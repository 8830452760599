import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { ReactComponent as PlusIcon } from '@icons/wolfkit-light/plus-light.svg';
import Button from '../../../shared/ui/buttons/Button';
const AddPortfolioButton = styled(Button)(props => ({
    padding: props.theme.spacing_sizes.l,
    maxHeight: 'unset',
    minHeight: 'unset',
    borderRadius: 10,
    background: '#F8FAFF',
    border: `2px dashed ${props.theme.palette.primary.main}`,
}));
const Divider = styled.div(props => ({
    border: `1px solid ${props.theme.customColors.button.pill.border}`,
    margin: `${props.theme.spacing_sizes.l}px 0`,
}));
const EmptyPortfoliosList = ({ hasError }) => {
    const { t } = useTranslation();
    if (hasError) {
        return null;
    }
    return (_jsxs(_Fragment, { children: [_jsx(Divider, {}), _jsx(AddPortfolioButton, { startIcon: PlusIcon, variant: 'tinted', color: 'primary', size: 'large', fullWidth: true, children: t('portfolio.add_portfolio_button') })] }));
};
export default EmptyPortfoliosList;
